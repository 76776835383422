import { useEffect, useState } from 'react';
import moment from 'moment';
import { useApolloClient } from '@apollo/client';
import { Button, Form, Input } from 'reactstrap';
import { errorMsg, successMsg } from '../../cache/vars';
import { DECLINE_SERVICE, GET_AVAILABLE_FILTERS_FOR_PACKAGE_INFO, SAVE_STAGE_ONE_CARD } from '../../queries/vip-workflow';
import { SERVICE_STATE } from '../../utils/constants';
import StagedCardLayout from '../StagedCard/StagedCardLayout/StagedCardLayout';
import CardDetails from '../StagedCard/CardDetails/CardDetails';
import GooglePlaceAutocompleteInput from '../GooglePlaceAutocomplete/GooglePlaceAutocomplete';
import CardDeclineBtn from '../StagedCard/CardDeclineBtn/CardDeclineBtn';
import CardGoogleMap from '../StagedCard/CardGoogleMap/CardGoogleMap';
import CardGuaranteed from '../StagedCard/CardGuaranteed/CardGuaranteed';
import CardAction from '../StagedCard/CardAction/CardAction';
import { generateMailtoLink } from '../../utils/services';
import GetHelpModal from '../Modal/GetHelpModal';
import reservationIcon from '../../assets/img/icons/cards/reservation-icon.svg';
import flagIcon from '../../assets/img/icons/cards/flag-icon.svg';
import partyPooperIcon from '../../assets/img/icons/cards/party-pooper-icon.svg';
import planeIcon from '../../assets/img/icons/cards/plane-icon.svg';
import helpIcon from '../../assets/img/icons/cards/help-icon.svg';
import carIcon from '../../assets/img/icons/cards/car-icon.svg';

import './vegasLimoTransferArrivalGroupCardNew.scss';

type VegasLimoTransferArrivalGroupCardNewProps = {
  cart: any;
  datetime: any | null;
  time: string;
  isMissingInfo: boolean;
  isPublished: boolean;
  isServiceDeclined: boolean;
  serviceDeclined: string;
  packageDetailsId: number;
  setPackageOrderWithDetails: (data: any) => void;
  decryptedInvoiceId: any;
  setMissingInfoCards: any;
  cardDescription: string;
  cardGroup: string;
};

const VegasLimoTransferArrivalGroupCardNew = ({
  cart,
  datetime,
  time,
  isMissingInfo,
  isPublished,
  isServiceDeclined,
  serviceDeclined,
  packageDetailsId,
  setPackageOrderWithDetails,
  decryptedInvoiceId,
  setMissingInfoCards,
  cardDescription,
  cardGroup,
}: VegasLimoTransferArrivalGroupCardNewProps) => {
  const client = useApolloClient();
  
  const [emailLink, setEmailLink] = useState("");

  const [cardState, setCardState] = useState<{ packageDetailsId: number; cartData: any }>({
    packageDetailsId,
    cartData: {},
  });

  const [isFieldsValid, setIsFieldsValid] = useState({
    contactName: true,
    contactNumber: true,
    pickUpName: true,
    pickUpAddress: true,
  });
  
  const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = useState(true);
  
  const [isModalOpen, setIsModalOpen] = useState(false);
  
  const handleDeclineClick = async () => {
    if (window.confirm("Are you sure you want to decline this service?")) {
      const { data } = await client.mutate({
        mutation: DECLINE_SERVICE,
        variables: { packageDetailsId: packageDetailsId },
      });

      if (data?.declineService) {
        setPackageOrderWithDetails((prev: any) => {
          return {
            ...prev,
            orderDetails: prev.orderDetails.map((item: any) => {
              return item.packageDetailsId === packageDetailsId
                ? {
                    ...item,
                    cartDetails: {
                      ...item.cartDetails,
                      cartId: data?.declineService,
                      isServiceDeclined: SERVICE_STATE.DECLINED,
                    },
                  }
                : item;
            }),
          };
        });
        successMsg("Service Declined");
      }
    }
  };
  
  useEffect(() => {
    const requiredFields = [
      "contactName",
      "contactNumber",
      "pickUpName",
      "pickUpAddress",
    ];

    const isRequiredFieldsEmpty = requiredFields.some((el) => {
      return !cardState.cartData[el];
    });

    setIsSubmitBtnDisabled(isRequiredFieldsEmpty);
  }, [cardState.cartData]);
  
  useEffect(() => {
    generateMailtoLink(client, window.location.pathname, setEmailLink);
  }, [client]);
  
  const cartJsonData = JSON.parse(cart.cartDetails.cartJsonData);
  
  return (
    <StagedCardLayout
      cardIcon={partyPooperIcon} 
      cardTitle={cart?.cartDetails?.cardTitle || "Vegas Limo Transfer Arrival"}
      datetime={datetime}
      time={time}
      isPublished={isPublished} 
      isServiceDeclined={isServiceDeclined}
      serviceDeclined={serviceDeclined}
      isMissingInfo={isMissingInfo}
    >
      <>
      <GetHelpModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} tag={cardGroup.replaceAll(' ', '')} />

        {isPublished &&
          <>
            <CardDetails
              cardIcon={carIcon}
              cardTitle="Vendor Name"
              cardSubtitle={cartJsonData?.vendorName}
            >
              <div className="vegas-limo-transfer-card-content">
                {cartJsonData?.vendorPhone &&
                  <>
                    <div className="card-content-column">
                      <p>Vendor Phone</p>
                    </div>
                    <div className="card-content-column">
                      <p>{cartJsonData?.vendorPhone}</p>
                    </div>
                  </>
                }
              </div>
            </CardDetails>
            
            <div className="divider" />
            
            <CardDetails
              cardIcon={carIcon}
              cardTitle="Pick Up At"
              cardSubtitle={moment(cartJsonData?.pickUp).format('h:mm A')}
            >
              <div className="vegas-limo-transfer-card-content">
                {cartJsonData?.pickUpName &&
                  <>
                    <div className="card-content-column">
                      <p>Pick Up Name</p>
                    </div>
                    <div className="card-content-column">
                      <p>{cartJsonData?.pickUpName}</p>
                    </div>
                  </>
                }
              </div>

              <div className="vegas-limo-transfer-card-content">
                {cartJsonData?.pickUpAddress &&
                  <>
                    <div className="card-content-column">
                      <p>Pick Up Address</p>
                    </div>
                    <div className="card-content-column">
                      <p>{cartJsonData?.pickUpAddress}</p>
                    </div>
                  </>
                }
              </div>
              <CardGoogleMap address={cartJsonData?.pickUpAddress} className="pl-4" />
              
              <div className="vegas-limo-transfer-card-content">
                {cartJsonData?.contactName &&
                  <>
                    <div className="card-content-column">
                      <p>Contact Name</p>
                    </div>
                    <div className="card-content-column">
                      <p>{cartJsonData?.contactName}</p>
                    </div>
                  </>
                }
              </div>
              
              <div className="vegas-limo-transfer-card-content">
                {cartJsonData?.contactNumber &&
                  <>
                    <div className="card-content-column">
                      <p>Contact Number</p>
                    </div>
                    <div className="card-content-column">
                      <p>{cartJsonData?.contactNumber}</p>
                    </div>
                  </>
                }
              </div>
            </CardDetails>
            
            <div className="divider" />
            
            <CardDetails
              cardIcon={carIcon}
              cardTitle="Transporting To"
              cardSubtitle=""
            >
              <div className="vegas-limo-transfer-card-content">
                {cartJsonData?.dropOffName &&
                  <>
                    <div className="card-content-column">
                      <p>Drop Off Name</p>
                    </div>
                    <div className="card-content-column">
                      <p>{cartJsonData?.dropOffName}</p>
                    </div>
                  </>
                }
              </div>

              <div className="vegas-limo-transfer-card-content">
                {cartJsonData?.dropOffAddress &&
                  <>
                    <div className="card-content-column">
                      <p>Drop Off Address</p>
                    </div>
                    <div className="card-content-column">
                      <p>{cartJsonData?.dropOffAddress}</p>
                    </div>
                  </>
                }
              </div>
              <CardGoogleMap address={cartJsonData?.dropOffAddress} className="pl-4" />
              
              <div className="vegas-limo-transfer-card-content">
                {cartJsonData?.celebration &&
                  <>
                    <div className="card-content-column">
                      <p>Celebration</p>
                    </div>
                    <div className="card-content-column">
                      <p>{cartJsonData?.celebration}</p>
                    </div>
                  </>
                }
              </div>
            </CardDetails>
            
            <div className="divider" />
            
            <CardDetails
              cardIcon={flagIcon}
              cardTitle="Additional Notes"
              cardDescription={cartJsonData?.notes || ""}
            />
          </>
        }

        {!isPublished && isMissingInfo && (
          <>
            <CardDetails
              isEditable={true}
              cardIcon={planeIcon}
              cardTitle="Arrival Details"
              cardSubtitle="Please provide your info"
            >
              <>
                <p className="mt-4 mr-4 mb-0 ml-2">
                  Provide us with your arrival flight information. Transfers are reserved in a vehicle accommodating the size of your party associated to the package. Special vehicle types and additional stops will require an additional charge.
                </p>
                
                <div className="divider" />
                
                <div className="mb-4">
                  <p>Contact Details</p>
                  <p className="required-note">* These fields are required.</p>
                </div>
                
                <Form
                  onSubmit={async (e) => {
                    e.preventDefault();
                    const requiredFields = [
                      "pickUpName",
                      "pickUpAddress",
                      "contactName",
                      "contactNumber",
                    ];

                    const isRequiredFieldsEmpty = requiredFields.some((el) => {
                      return !cardState.cartData[el];
                    });

                    if (isRequiredFieldsEmpty) {
                      setIsFieldsValid({
                        contactName: !!cardState.cartData.contactName,
                        contactNumber: !!cardState.cartData.contactNumber,
                        pickUpName: !!cardState.cartData.pickUpName,
                        pickUpAddress: !!cardState.cartData.pickUpAddress,
                      });

                      errorMsg("Required Fields Should Not Be Empty");

                      return;
                    }

                    const { data } = await client.mutate({
                      mutation: SAVE_STAGE_ONE_CARD,
                      variables: { editCartDetailsInput: cardState },
                    });

                    const filtersData = await client.query({
                      query: GET_AVAILABLE_FILTERS_FOR_PACKAGE_INFO,
                      variables: { invoiceId: decryptedInvoiceId },
                      fetchPolicy: "no-cache",
                    });

                    if (data) {
                      setPackageOrderWithDetails((prev: any) => {
                        return {
                          ...prev,
                          orderDetails: [
                            ...prev.orderDetails.map((cart: any) => {
                              return cart.packageDetailsId === packageDetailsId
                                ? {
                                    ...cart,
                                    cartDetails: {
                                      ...cart.cartDetails,
                                      cardTitle: data?.saveStageOneCard.cardTitle,
                                      cartId: data?.saveStageOneCard.cartId,
                                      cartJsonData: data?.saveStageOneCard.cartJsonData,
                                      isPublished: data?.saveStageOneCard.isPublished,
                                      packageDetailsId: data?.saveStageOneCard.packageDetailsId,
                                    },
                                  }
                                : cart;
                            }),
                          ],
                        };
                      });
                      
                      setMissingInfoCards(
                        filtersData.data?.getAvailableFiltersForPackageInfo.showMissingInfoFor,
                      );

                      successMsg("Saved");
                    }
                  }}
                >
                    <div className="inputs-container">
                        <div className="input-container">
                        <label className={!isFieldsValid.contactName ? "label-invalid" : ""}>
                            Contact Name
                        </label>
                        <div className="input-box">
                          <Input
                          className={!isFieldsValid.contactName ? "input-invalid" : ""}
                          type="text"
                          placeholder="Contact Name"
                          name="contactName"
                          invalid={!isFieldsValid.contactName}
                          onChange={(e) => {
                              setCardState((prev: any) => {
                              return {
                                  ...prev,
                                  cartData: {
                                  ...prev.cartData,
                                  [e.target.name]: e.target.value,
                                  },
                              };
                              });
                          }}
                          value={cardState.cartData.contactName || ""}
                          />
                          <span></span>
                        </div>
                        </div>
                        <div className="input-container">
                        <label className={!isFieldsValid.contactNumber ? "label-invalid" : ""}>
                          Contact Number
                        </label>
                        <div className="input-box">
                          <Input
                          className={!isFieldsValid.contactNumber ? "input-invalid" : ""}
                          type="text"
                          placeholder="Contact Number"
                          name="contactNumber"
                          invalid={!isFieldsValid.contactNumber}
                          onChange={(e) => {
                              setCardState((prev: any) => {
                              return {
                                  ...prev,
                                  cartData: {
                                  ...prev.cartData,
                                  [e.target.name]: e.target.value,
                                  },
                              };
                              });
                          }}
                          value={cardState.cartData.contactNumber || ""}
                          />
                          <span></span>
                        </div>
                        </div>{" "}
                        <div className="input-container">
                        <label className={!isFieldsValid.pickUpName ? "label-invalid" : ""}>Pick-up Name</label>
                        <div className="input-box">
                            <Input
                            className={!isFieldsValid.pickUpName ? "input-invalid" : ""}
                            type="text"
                            placeholder="Pick-up Name"
                            name="pickUpName"
                            invalid={!isFieldsValid.pickUpName}
                            onChange={(e) => {
                                setCardState((prev: any) => {
                                return {
                                  ...prev,
                                  cartData: {
                                  ...prev.cartData,
                                  [e.target.name]: e.target.value,
                                  },
                                };
                                });
                            }}
                            value={cardState.cartData.pickUpName || ""}
                            />
                            <span></span>
                        </div>
                        </div>{" "}
                        <div className="input-container">
                        <label className={!isFieldsValid.pickUpAddress ? "label-invalid": ""}>Pick-up Address</label>
                        <div className="input-box">
                          <GooglePlaceAutocompleteInput
                            cardState={cardState}
                            setCardState={setCardState}
                            placeName={"pickUpAddress"}
                            placeValueName={"pickUpAddressValue"}
                            placeLinkName={"pickUpPlaceLink"}
                          />
                          <span></span>
                        </div>
                        </div>{" "}
                        <div className="input-container">
                        <label>Celebration</label>
                        <div className="input-box">
                            <Input
                            type="text"
                            placeholder="Are you celebrating a special occasion?"
                            name="celebration"
                            onChange={(e) => {
                              setCardState((prev: any) => {
                                return {
                                  ...prev,
                                  cartData: {
                                  ...prev.cartData,
                                  [e.target.name]: e.target.value,
                                  },
                                };
                              });
                            }}
                            value={cardState.cartData.celebration || ""}
                            />
                            <span></span>
                        </div>
                        </div>{" "}
                    </div>
                  
                  <div className="actions-container">
                    <CardDeclineBtn visible={isServiceDeclined} handleClick={handleDeclineClick} />
                    <Button disabled={isSubmitBtnDisabled} className="btn-round btn-icon d-flex align-items-center save-btn" type="submit">
                      Save
                    </Button>
                  </div>
                </Form>
              </>
            </CardDetails>

            <div className="divider"></div> 

            <CardAction icon={helpIcon} text="Get help" 
              handleClick={() => {
                setIsModalOpen(true)
              }}
            />
          </>
        )}

        {!isPublished && !isMissingInfo &&
          <>
            <CardDetails
              isToggleExpanded={false}
              cardIcon={reservationIcon}
              cardTitle="Departure Reservation Details"
              cardSubtitle="Your transportation is guaranteed."
            >
              <>
                <CardGuaranteed text="Your transfer is guaranteed." />
                <div className="actions-container">
                  <CardDeclineBtn visible={isServiceDeclined} handleClick={handleDeclineClick} />
                </div>
              </>
            </CardDetails>
            
            <div className="divider"></div>

            <CardAction icon={helpIcon} text="Get help" 
              handleClick={() => {
                setIsModalOpen(true)
              }}
            />
          </>
        }
      </>
    </StagedCardLayout>
  );
};

export default VegasLimoTransferArrivalGroupCardNew;