import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Modal, ModalBody, ModalFooter, Form, FormGroup, Label, Input, Button } from "reactstrap";
import { useApolloClient, useMutation } from "@apollo/client";
import CryptoJS from "crypto-js/aes";
import enc from "crypto-js/enc-utf8";
import { GET_PACKAGE_WITH_DETAILS_BY_INVOICE_ID, CREATE_SUPPORT_TICKET } from "../../queries/vip-workflow";
import { CRYPTO_JS_SECRET } from "../../utils/constants";
import { errorMsg, successMsg } from "../../cache/vars";
import ticketsIcon from "../../assets/img/icons/cards/tickets-icon.svg";

import "./getHelpModal.scss";

const GetHelpModal = ({isModalOpen, setIsModalOpen, tag = 'vipgetintouch'}: any) => {
  const client = useApolloClient();
  const { pathname } = useLocation();
  const [packageDetails, setPackageDetails] = useState<Record<string, any>>();
  const [userMessage, setUserMessage] = useState("");
  const [loading, setLoading] = useState(false);
  
  const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = useState(true);

  useEffect(() => {
    getPackageInfoForSupportEmail(pathname);
  }, []);
  
  useEffect(() => {
    const isRequiredFieldsEmpty = !!userMessage;

    setIsSubmitBtnDisabled(isRequiredFieldsEmpty);
  }, [userMessage]);

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const getPackageInfoForSupportEmail = async (pathname: string) => {
    if (pathname.match("/package-info")) {
      const slashesReturnedToEncryptedInvoiceId = pathname
        .replace(/HjsOwpeSBqdLafaFsjhHfd73sb7kdheYTsd61/g, "/")
        .replace("/package-info/", "");

      const decryptedInvoiceId = Number.parseFloat(
        CryptoJS.decrypt(slashesReturnedToEncryptedInvoiceId.toString(), CRYPTO_JS_SECRET).toString(
          enc,
        ),
      );

      if (Number.isNaN(decryptedInvoiceId)) return;

      const { data } = await client.query({
        query: GET_PACKAGE_WITH_DETAILS_BY_INVOICE_ID,
        variables: { invoiceId: decryptedInvoiceId, orderDetailsFilter: "" },
        fetchPolicy: "cache-first",
      });

      const packageDetailsForClient = data?.getOnePackageWithDetailsForClient;
      setPackageDetails({...packageDetailsForClient});
    }
  };
  
  const [supportTicket] = useMutation(CREATE_SUPPORT_TICKET, {
    variables: {},
  });
  
  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setLoading(true);
  
    if (!packageDetails?.recipientEmail || !packageDetails?.invoiceId) {
      errorMsg("User email or Invoice ID is missing. Please check.");
      setLoading(false);
      return;
    }
    
    const customFields = [
      { id: 29233810215181, value: packageDetails?.invoiceId},
      { id: 29233867907469, value: packageDetails?.ticketRequestId },
      { id: 29233913128717, value: packageDetails?.client },
      { id: 29233885004813, value: packageDetails?.recipient },
      { id: 29233875654285, value: packageDetails?.eventName },
      { id: 29233934778253, value: packageDetails?.venue },
      { id: 29233894659341, value: packageDetails?.qty },
      { id: 29233948633101, value: packageDetails?.row },
      { id: 29233907758605, value: packageDetails?.section },
      { id: 29002772709901, value: userMessage },
    ];
  
    try {
      const { data } = await supportTicket({
        variables: {
          inputGetInTouch: {
            recipientEmail: packageDetails.recipientEmail,
            group: 'app.elevatevip',
            subject: `Support Request: Invoice ${packageDetails.invoiceId}`,
            description: `\n\nEvent Name: ${packageDetails?.eventName}\nRecipient: ${packageDetails?.recipient}\nVenue: ${packageDetails?.venue}\nInvoice ID: ${packageDetails?.invoiceId}\nTicket Request ID: ${packageDetails?.ticketRequestId}\nClient: ${packageDetails?.client}\nQuantity: ${packageDetails?.qty}\nRow: ${packageDetails?.row}\nSection: ${packageDetails?.section}\n\nClient notes:\n${userMessage}`,
            customFields: customFields,
            tags: tag
          }
        },
      });
  
      if (data.supportTicket) {
        successMsg('Your request has been submitted successfully.');
        setUserMessage('');
        toggleModal();
      } else {
        console.error('Submission failed');
      }
    } catch (error) {
      console.error('Error submitting support ticket:', error);
      errorMsg("There was an issue submitting your request. Please try again.'");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal isOpen={isModalOpen} toggle={toggleModal} centered>
      <div className="d-flex justify-content-between p-4">
      <div className="d-flex align-items-center">
          <img className="mr-2" src={ticketsIcon} alt="tickets-icon" />
          <span className="tickets-details-span">How can we help you?</span>
      </div>
      <div className="d-flex align-items-center">
          <button
          style={{ background: "none", border: "none" }}
          onClick={(e) => {
              e.preventDefault();
              setIsModalOpen(false);
          }}
          >
          <i className="fas fa-times" aria-hidden="true" />
          </button>
      </div>
      </div>
      <ModalBody>
      <Form onSubmit={handleSubmit}>
          <FormGroup style={{marginBottom: '0px'}}>
          <Input
              style={{ minHeight: '150px'}}
              type="textarea"
              name="message"
              id="helpMessage"
              value={userMessage}
              onChange={(e) => setUserMessage(e.target.value)}
              placeholder="Describe your issue or question"
              required
          />
          </FormGroup>
          
          <FormGroup style={{ display: 'none' }}>
          <Label for="invoiceId">Invoice ID</Label>
          <Input type="text" name="invoiceId" id="invoiceId" value={packageDetails?.invoiceId} disabled />
          </FormGroup>
          
          <FormGroup style={{ display: 'none' }}>
          <Label for="ticketRequestId">Order ID</Label>
          <Input type="text" name="ticketRequestId" id="ticketRequestId" value={packageDetails?.ticketRequestId} disabled />
          </FormGroup>
          
          <FormGroup style={{ display: 'none' }}>
          <Label for="recipient">Recipient Name</Label>
          <Input type="text" name="recipient" id="recipient" value={packageDetails?.recipient} disabled />
          </FormGroup>
          
          <FormGroup style={{ display: 'none' }}>
          <Label for="client">Customer Name</Label>
          <Input type="text" name="client" id="client" value={packageDetails?.client} disabled />
          </FormGroup>
          
          <FormGroup style={{ display: 'none' }}>
          <Label for="eventName">Event Name</Label>
          <Input type="text" name="eventName" id="eventName" value={packageDetails?.eventName} disabled />
          </FormGroup>

          <FormGroup style={{ display: 'none' }}>
          <Label for="venue">Venue</Label>
          <Input type="text" name="venue" id="venue" value={packageDetails?.venue} disabled />
          </FormGroup>
          
          <FormGroup style={{ display: 'none' }}>
          <Label for="qty">Quantity</Label>
          <Input type="text" name="qty" id="qty" value={packageDetails?.qty} disabled />
          </FormGroup>
          
          <FormGroup style={{ display: 'none' }}>
          <Label for="section">Section</Label>
          <Input type="text" name="section" id="section" value={packageDetails?.section} disabled />
          </FormGroup>
          
          <FormGroup style={{ display: 'none' }}>
          <Label for="row">Venue</Label>
          <Input type="text" name="row" id="row" value={packageDetails?.row} disabled />
          </FormGroup>
      </Form>
      </ModalBody>
      <ModalFooter>
      <Button className="btn btn-primary d-flex align-items-center save-btn" type="submit" onClick={handleSubmit} disabled={!isSubmitBtnDisabled || loading} style={{ backgroundColor: '#18181b', color: '#fff', fontSize: '14px', fontWeight: '500', borderRadius: '10px'}}>
          {loading ? "Submitting..." : "Submit"}
      </Button>
      <Button className="btn btn-secondary" style={{ fontSize: '14px', fontWeight: '500', borderRadius: '10px' }} onClick={toggleModal}>
        Cancel
      </Button>
      </ModalFooter>
    </Modal>
  );
};

export default GetHelpModal;
