import { Col, Row } from "reactstrap";
import { DEFAULT_CARD_CONFIG } from "../../utils/constants";
import TransferTypeButtons from "../Buttons/TransferTypeButtons";
import CartLayout from "./CardLayout";

import "./card.scss";


const Cart = ({
  cartSchemaConfig,
  cartData,
  airportTransferType,
  setAirportTransferType,
  vegasLimoTransferType,
  setVegasLimoTransferType,
  dataKey,
  isCartInfoOpenedId,
  handleOpenDetails,
  parsedCardDetails,
  cartInfoId,
  handleOpenLearnMore,
  isLearnMoreOpenedId,
}: any) => {
  if (dataKey === "Airport Transfer") {
    const parsedAirportTransfer = JSON.parse(cartData.cartDetails.cartJsonData);

    if (!cartData?.cartDetails.isPublished) {
      return (
        <CartLayout
          schemaConfig={DEFAULT_CARD_CONFIG[0]}
          parsedCardDetails={cartData}
          cartInfoId={cartInfoId}
          dataKey={dataKey}
        />
      );
    }
    return (
      <div key={cartInfoId}>
        {parsedAirportTransfer.transfer1 && parsedAirportTransfer.transfer2 ? (
          <Col>
            <TransferTypeButtons
              airportTransferType={airportTransferType}
              setAirportTransferType={setAirportTransferType}
              transfer1Date={parsedAirportTransfer.transfer1.date1}
              transfer2Date={parsedAirportTransfer.transfer2.date2}
            />
          </Col>
        ) : null}
        <div
          className={
            parsedAirportTransfer.transfer1 && parsedAirportTransfer.transfer2 ? "mt-4" : ""
          }
        >
          {cartSchemaConfig.map((transferSchemaConfig: any, id: number) => {
            const isCardDetailsOpened =
              transferSchemaConfig.cartInfo &&
              cartData.cartDetails &&
              isCartInfoOpenedId.includes(
                cartData.cartDetails?.packageDetailsId &&
                  (parsedAirportTransfer?.transferId || parsedCardDetails?.transferId),
              );

            if (
              transferSchemaConfig.hideIfEmpty &&
              !parsedCardDetails?.[transferSchemaConfig.name as keyof typeof cartData]
            ) {
              return;
            }

            if (isCardDetailsOpened) {
              return Object.entries(transferSchemaConfig).map(
                ([cardDetailsKey, cardDetailsConfig]: any, idx) => {
                  return (
                    <CartLayout
                      schemaConfig={cardDetailsConfig}
                      cartData={cartData}
                      isCartInfoOpenedId={isCartInfoOpenedId}
                      handleOpenDetails={handleOpenDetails}
                      parsedCardDetails={
                        parsedAirportTransfer.transfer1 && parsedAirportTransfer.transfer2
                          ? parsedCardDetails
                          : parsedAirportTransfer
                      }
                      cartInfoId={cartInfoId}
                      key={idx}
                    />
                  );
                },
              );
            }

            if (transferSchemaConfig.skipInCart) {
              return;
            }

            return (
              <CartLayout
                schemaConfig={transferSchemaConfig}
                cartData={cartData}
                isCartInfoOpenedId={isCartInfoOpenedId}
                handleOpenDetails={handleOpenDetails}
                parsedCardDetails={
                  parsedAirportTransfer.transfer1 && parsedAirportTransfer.transfer2
                    ? parsedCardDetails
                    : parsedAirportTransfer
                }
                cartInfoId={cartInfoId}
                dataKey={dataKey}
                key={id}
              />
            );
          })}
        </div>
      </div>
    );
  }
  
  if (dataKey === "Vegas Limo Transfer") {
    const parsedVegasLimoTransfer = JSON.parse(cartData.cartDetails.cartJsonData);
    if (!cartData?.cartDetails.isPublished) {
      return (
        <CartLayout
          schemaConfig={DEFAULT_CARD_CONFIG[0]}
          parsedCardDetails={cartData}
          cartInfoId={cartInfoId}
          dataKey={dataKey}
        />
      );
    }
    return (
      <div key={cartInfoId}>
        {parsedVegasLimoTransfer.transfer1 && parsedVegasLimoTransfer.transfer2 ? (
          <Col>
            <TransferTypeButtons
              vegasLimoTransferType={vegasLimoTransferType}
              setVegasLimoTransferType={setVegasLimoTransferType}
              transfer1PickUp1={parsedVegasLimoTransfer.transfer1.pickUp1}
              transfer2PickUp2={parsedVegasLimoTransfer.transfer2.pickUp2}
            />
          </Col>
        ) : null}
        <div
          className={
            parsedVegasLimoTransfer.transfer1 && parsedVegasLimoTransfer.transfer2 ? "mt-4" : ""
          }
        >
          {cartSchemaConfig?.map((schemaConfig: any, id: number) => {
            const isCardDetailsOpened =
              schemaConfig.cartInfo &&
              cartData.cartDetails &&
              isCartInfoOpenedId.includes(
                cartData.cartDetails?.packageDetailsId &&
                  (parsedVegasLimoTransfer?.transferId || parsedCardDetails?.transferId),
              );

            if (
              schemaConfig.hideIfEmpty &&
              !parsedCardDetails?.[schemaConfig.name as keyof typeof cartData]
            ) {
              return;
            }

            if (isCardDetailsOpened) {
              return Object.entries(schemaConfig)?.map(
                ([cardDetailsKey, cardDetailsConfig]: any, idx) => {
                  return (
                    <CartLayout
                      schemaConfig={cardDetailsConfig}
                      cartData={cartData}
                      isCartInfoOpenedId={isCartInfoOpenedId}
                      handleOpenDetails={handleOpenDetails}
                      parsedCardDetails={
                        parsedVegasLimoTransfer.transfer1 && parsedVegasLimoTransfer.transfer2
                          ? parsedCardDetails
                          : parsedVegasLimoTransfer
                      }
                      cartInfoId={cartInfoId}
                      key={idx}
                    />
                  );
                },
              );
            }

            if (schemaConfig.skipInCart) {
              return;
            }
            return (
              <CartLayout
                schemaConfig={schemaConfig}
                cartData={cartData}
                isCartInfoOpenedId={isCartInfoOpenedId}
                handleOpenDetails={handleOpenDetails}
                parsedCardDetails={
                  parsedVegasLimoTransfer.transfer1 && parsedVegasLimoTransfer.transfer2
                    ? parsedCardDetails
                    : parsedVegasLimoTransfer
                }
                cartInfoId={cartInfoId}
                dataKey={dataKey}
                key={id}
              />
            );
          })}
        </div>
      </div>
    );
  }

  if (
    dataKey === "MLB Card" ||
    dataKey === "NBA Card" ||
    dataKey === "NHL Card" ||
    dataKey === "Multi-day Gift Card"
  ) {
    const isMobile = window.innerWidth < 768;
    return (
      <div className="d-flex flex-wrap" style={{ marginTop: "-6px" }}>
        {parsedCardDetails.length &&
          parsedCardDetails?.map((item: any, id: number) => {
            return cartSchemaConfig?.map((field: any, idx: number) => {
              if (field.hideIfEmpty && !item?.[field.name as keyof typeof cartData]) {
                return null;
              }
              if (field.name.toLowerCase() === 'elink') {
                return (
                  <a
                  className=""
                  href={item['eLink']}
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: "#007bff" }}
                  >
                    {item['eLink']}
                  </a>
                );
                
              }

              if (field.name === "description" && item.hasOwnProperty(field.name)) {
                return (
                  <Col
                    xs="12"
                    style={{
                      ...field.style,
                      paddingLeft: field.icon ? "" : "1.25rem",
                    }}
                    className="d-flex p-0"
                    key={idx}
                  >
                    {field.icon ? (
                      <div>
                      <img src={field.icon} alt="copy-icon" 
                        style={{
                          width: "24px",
                          textAlign: "center",
                          marginRight: "0.5rem",
                        }}
                      />
                      </div>
                    ) : null}
                    <span
                      style={{ whiteSpace: "pre-line" }}
                      dangerouslySetInnerHTML={{
                        __html: item?.[field.name as any],
                      }}
                    ></span>
                  </Col>
                );
              }

              if (item.hasOwnProperty(field.name)) {
                return field.name === "notes" ? (
                  <>
                    <Col
                      xs="6"
                      style={{ ...field.style, paddingLeft: field.icon ? "" : "2.25rem" }}
                      className="d-flex"
                      key={idx}
                    >
                      {field.icon ? (
                        <div>
                          <img src={field.icon} alt="copy-icon" 
                            style={{
                              width: "24px",
                              textAlign: "center",
                              marginRight: "0.5rem",
                            }}
                          />
                        </div>
                      ) : null}
                      <span className="">{field.label}</span>
                    </Col>
                    <Col
                      xs="6"
                      style={{ ...field.style, paddingLeft: field.icon ? "" : "2.25rem" }}
                    >
                      <span className="">
                        {field.name !== "notes" ? ":" : ""} {item[field.name]}
                      </span>
                    </Col>
                  </>
                ) : (
                  <Col
                    xs={!isMobile ? "6" : "12"}
                    style={{
                      ...field.style,
                      paddingLeft: "2rem",
                    }}
                    className="d-flex"
                    key={idx}
                  >
                    <span className="">
                      {field.label}
                      {field.name !== "notes" ? ":" : ""} {item[field.name]}
                    </span>
                  </Col>
                );
              }
              
              return null;
            });
          })}
      </div>
    );
  }
  
  if (
    dataKey === "Fanatics Credit"
  ) {
    const isLink =  parsedCardDetails?.some((item: any) => 'eLink' in item);
    const isMobile = window.innerWidth < 768;
    return (
      <div className="d-flex flex-wrap" style={{ marginTop: "-6px" }}>
        {parsedCardDetails.length &&
          parsedCardDetails?.filter((item: any) => {
            if (isLink && 'eGiftCode' in item) {
              return false;
            }
            return true;
          }).map((item: any, id: number) => {
            return cartSchemaConfig?.map((field: any, idx: number) => {
              if (field.hideIfEmpty && !item?.[field.name as keyof typeof cartData]) {
                return null;
              }

              if (field.name === "description" && item.hasOwnProperty(field.name)) {
                const description = item?.[field.name as any]?.split("${or}");
                return (
                  <Col
                    xs="12"
                    style={{
                      ...field.style,
                      paddingLeft: field.icon ? "" : "1.25rem",
                    }}
                    className="d-flex p-0"
                    key={idx}
                  >
                    {field.icon ? (
                      <div>
                        <img src={field.icon} alt="copy-icon" 
                          style={{
                            width: "24px",
                            textAlign: "center",
                            marginRight: "0.5rem",
                          }}
                        />
                      </div>
                    ) : null}
                    <span
                      style={{ whiteSpace: "pre-line" }}
                      dangerouslySetInnerHTML={{
                        __html: isLink ? description[1] : description[0],
                      }}
                    ></span>
                  </Col>
                );
              }

              if (item.hasOwnProperty(field.name)) {
                return field.name === "notes" ? (
                  <>
                    <Col
                      xs="6"
                      style={{ ...field.style, paddingLeft: field.icon ? "" : "2.25rem" }}
                      className="d-flex"
                      key={idx}
                    >
                      {field.icon ? (
                        <div>
                          <img src={field.icon} alt="copy-icon" 
                            style={{
                              width: "24px",
                              textAlign: "center",
                              marginRight: "0.5rem",
                            }}
                          />
                        </div>
                      ) : null}
                      <span className="">{field.label}</span>
                    </Col>
                    <Col
                      xs="6"
                      style={{ ...field.style, paddingLeft: field.icon ? "" : "2.25rem" }}
                    >
                      <span className="">
                        {field.name !== "notes" ? ":" : ""} {item[field.name]}
                      </span>
                    </Col>
                  </>
                ) : (
                  <Col
                    xs={!isMobile ? (isLink ? "12" : "6") : "12"}
                    style={{
                      ...field.style,
                      paddingLeft: "2rem",
                    }}
                    className="d-flex"
                    key={idx}
                  >
                    <span className="e-field">
                      {field.label}: {isLink ? <a href={item[field.name]} target="_blank" rel="noreferrer">{item['eLink']}</a> : item[field.name]}
                    </span>
                  </Col>
                );
              }
              
              return null;
            });
          })}
      </div>
    );
  }

  // if (dataKey === "City Pass") {
  //   return (
  //     <div>
  //       {cartSchemaConfig.map((schemaConfig: any, id: number) => {
  //         const isCardDetailsOpened =
  //           schemaConfig.cartInfo &&
  //           cartData.cartDetails &&
  //           isCartInfoOpenedId.includes(cartData.cartDetails.packageDetailsId);

  //         if (
  //           schemaConfig.hideIfEmpty &&
  //           !parsedCardDetails?.[schemaConfig.name as keyof typeof cartData]
  //         ) {
  //           return;
  //         }

  //         if (isCardDetailsOpened) {
  //           return parsedCardDetails.attendeeNames.map(
  //             (attendee: { attendeeName: string }, id: number) => {
  //               return Object.entries(schemaConfig).map(
  //                 ([cartDetailsKey, cartDetailsConfig]: any) => {
  //                   if (cartDetailsKey === "cartInfo" || cartDetailsKey === "checkPending") return;

  //                   return (
  //                     <CartLayout
  //                       schemaConfig={cartDetailsConfig}
  //                       cartData={cartData}
  //                       isCartInfoOpenedId={isCartInfoOpenedId}
  //                       handleOpenDetails={handleOpenDetails}
  //                       parsedCardDetails={attendee}
  //                       cartInfoId={id}
  //                     />
  //                   );
  //                 },
  //               );
  //             },
  //           );
  //         }

  //         return (
  //           <Row
  //             style={{
  //               ...schemaConfig.style,
  //               paddingLeft: "15px",
  //               paddingRight: "15px",
  //               marginTop: "0px",
  //             }}
  //           >
  //             <Col
  //               xs={schemaConfig.alignToLeft ? "11" : "6"}
  //               style={{ ...schemaConfig.style, paddingLeft: schemaConfig.icon ? "" : "3.25rem" }}
  //               className={schemaConfig.bold ? "d-flex font-weight-bold" : "d-flex"}
  //             >
  //               {schemaConfig.icon ? (
  //                 <div>
  //                   <i
  //                     className={schemaConfig.icon}
  //                     style={{ width: "24px", textAlign: "center", marginRight: "0.5rem" }}
  //                   />
  //                 </div>
  //               ) : null}
  //               <span style={{ whiteSpace: "pre-line" }}>
  //                 {schemaConfig.alignToLeft
  //                   ? schemaConfig.render
  //                     ? schemaConfig.render(
  //                         parsedCardDetails?.[schemaConfig.name as keyof typeof parsedCardDetails],
  //                         parsedCardDetails?.[
  //                           schemaConfig.showDataFrom as keyof typeof parsedCardDetails
  //                         ],
  //                       )
  //                     : parsedCardDetails?.[schemaConfig.name as any]
  //                   : null}
  //                 {typeof schemaConfig.label === "function"
  //                   ? schemaConfig.label(
  //                       parsedCardDetails?.[schemaConfig.name as any]
  //                         ? parsedCardDetails[schemaConfig.name as any]
  //                         : "-",
  //                     )
  //                   : schemaConfig.labelInCart
  //                   ? schemaConfig.labelInCart
  //                   : schemaConfig.label}
  //               </span>
  //             </Col>
  //             {schemaConfig.showMoreButton ? (
  //               <Col style={{ marginTop: "20px" }}>
  //                 <button
  //                   style={{ border: "none", background: "none", marginLeft: "15px" }}
  //                   onClick={(e) => handleOpenDetails(e, cartData.cartDetails.packageDetailsId)}
  //                 >
  //                   {isCartInfoOpenedId.includes(
  //                     (cartData.cartDetails?.packageDetailsId && parsedCardDetails?.transferId) ||
  //                       cartData.cartDetails?.packageDetailsId ||
  //                       parsedCardDetails?.transferId,
  //                   ) ? (
  //                     <i className="fa fa-caret-up text-body fa-lg" />
  //                   ) : (
  //                     <i className="fa fa-caret-down text-body fa-lg" />
  //                   )}
  //                 </button>
  //               </Col>
  //             ) : null}
  //           </Row>
  //         );
  //       })}
  //     </div>
  //   );
  // }

  return (
    <div key={cartInfoId}>
      {cartSchemaConfig?.map((schemaConfig: any, id: number) => {
        const isCardDetailsOpened =
          schemaConfig.cartInfo &&
          cartData.cartDetails &&
          isCartInfoOpenedId.includes(cartData.cartDetails.packageDetailsId);

        const isLearnMoreOpened =
          schemaConfig.learnMore &&
          isLearnMoreOpenedId.includes(cartData.cartDetails.packageDetailsId);

        if (schemaConfig.skipInCart) {
          return;
        }

        if (
          schemaConfig.hideIfEmpty &&
          !parsedCardDetails?.[schemaConfig.name as keyof typeof cartData]
        ) {
          return;
        }

        if (
          schemaConfig.hideIfFieldWithNameHasValue &&
          parsedCardDetails?.[schemaConfig.hideIfFieldWithNameHasValue]
        ) {
          return;
        }

        if (isCardDetailsOpened) {
          return Object.entries(schemaConfig)?.map(
            ([cartDetailsKey, cartDetailsConfig]: any, idx) => {
              if (cartDetailsKey === "cartInfo" || cartDetailsKey === "checkPending") return;

              return (
                <CartLayout
                  schemaConfig={cartDetailsConfig}
                  cartData={cartData}
                  isCartInfoOpenedId={isCartInfoOpenedId}
                  handleOpenDetails={handleOpenDetails}
                  parsedCardDetails={parsedCardDetails}
                  cartInfoId={id}
                  key={idx}
                />
              );
            },
          );
        }

        if (isLearnMoreOpened) {
          return (
            <CartLayout
              schemaConfig={schemaConfig}
              cartData={cartData}
              isCartInfoOpenedId={isCartInfoOpenedId}
              handleOpenDetails={handleOpenDetails}
              parsedCardDetails={parsedCardDetails}
              cartInfoId={id}
              handleOpenLearnMore={handleOpenLearnMore}
              isLearnMoreOpenedId={isLearnMoreOpenedId}
              isLearnMoreOpened={isLearnMoreOpened}
              key={id}
            />
          );
        }

        return (
          <CartLayout
            schemaConfig={schemaConfig}
            cartData={cartData}
            isCartInfoOpenedId={isCartInfoOpenedId}
            handleOpenDetails={handleOpenDetails}
            parsedCardDetails={parsedCardDetails}
            cartInfoId={id}
            handleOpenLearnMore={handleOpenLearnMore}
            isLearnMoreOpenedId={isLearnMoreOpenedId}
            key={id}
          />
        );
      })}
    </div>
  );
};

export default Cart;
