import { useEffect, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import { Button, Form, Input } from 'reactstrap';
import moment from 'moment';
import { errorMsg, successMsg } from '../../cache/vars';
import { DECLINE_SERVICE, GET_AVAILABLE_FILTERS_FOR_PACKAGE_INFO, SAVE_STAGE_ONE_CARD } from '../../queries/vip-workflow';
import { SERVICE_STATE } from '../../utils/constants';
import StagedCardLayout from '../StagedCard/StagedCardLayout/StagedCardLayout';
import CardDetails from '../StagedCard/CardDetails/CardDetails';
import CardAction from '../StagedCard/CardAction/CardAction';
import reservationIcon from '../../assets/img/icons/cards/reservation-icon.svg';
import flagIcon from '../../assets/img/icons/cards/flag-icon.svg';
import buildingIcon from "../../assets/img/icons/cards/building-icon.svg";
import helpIcon from '../../assets/img/icons/cards/help-icon.svg';
import CardDeclineBtn from '../StagedCard/CardDeclineBtn/CardDeclineBtn';
import CardGuaranteed from '../StagedCard/CardGuaranteed/CardGuaranteed';
import { generateMailtoLink } from '../../utils/services';
import GetHelpModal from '../Modal/GetHelpModal';

import './cityPassGroupCardNew.scss';

type CityPassGroupCardNewProps = {
  cart: any;
  datetime: any | null;
  isMissingInfo: boolean;
  isPublished: boolean;
  isServiceDeclined: boolean;
  serviceDeclined: string;
  packageDetailsId: number;
  setPackageOrderWithDetails: (data: any) => void;
  decryptedInvoiceId: any;
  setMissingInfoCards: any;
  cardDescription: string;
  cardGroup: string;
  attendeeQty: number;
};

const CityPassGroupCardNew = ({
  cart,
  datetime,
  isMissingInfo,
  isPublished,
  isServiceDeclined,
  serviceDeclined,
  packageDetailsId,
  setPackageOrderWithDetails,
  decryptedInvoiceId,
  setMissingInfoCards,
  attendeeQty,
  cardGroup,
}: CityPassGroupCardNewProps) => {
  const client = useApolloClient();
  
  const [emailLink, setEmailLink] = useState("");

  const [cardState, setCardState] = useState<{ packageDetailsId: number; cartData: any }>({
    packageDetailsId,
    cartData: { attendeeNames: [...new Array(attendeeQty).fill({ attendeeName: "" })] },
  });

  const [isFieldsValid, setIsFieldsValid] = useState<number[]>([]);
  
  const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = useState(true);
  
  const [isModalOpen, setIsModalOpen] = useState(false);
  
  const handleDeclineClick = async () => {
    if (window.confirm("Are you sure you want to decline this service?")) {
      const { data } = await client.mutate({
        mutation: DECLINE_SERVICE,
        variables: { packageDetailsId: packageDetailsId },
      });

      if (data?.declineService) {
        setPackageOrderWithDetails((prev: any) => {
          return {
            ...prev,
            orderDetails: prev.orderDetails.map((item: any) => {
              return item.packageDetailsId === packageDetailsId
                ? {
                    ...item,
                    cartDetails: {
                      ...item.cartDetails,
                      cartId: data?.declineService,
                      isServiceDeclined: SERVICE_STATE.DECLINED,
                    },
                  }
                : item;
            }),
          };
        });
        successMsg("Service Declined");
      }
    }
  };
  
  useEffect(() => {
    const emptyRequiredFieldsIds = cardState.cartData.attendeeNames.reduce(
      (prev: any, el: { attendeeName: string }, id: number) => {
        if (!el.attendeeName) {
          prev.push(id);
        }

        return prev;
      },
      [],
    );

    if (emptyRequiredFieldsIds.length) {
      setIsSubmitBtnDisabled(true);
    } else {
      setIsSubmitBtnDisabled(false);
    }
  }, [cardState.cartData]);
  
  useEffect(() => {
    generateMailtoLink(client, window.location.pathname, setEmailLink);
  }, [client]);
  
  const cartJsonData = JSON.parse(cart.cartDetails.cartJsonData);

  return (
    <StagedCardLayout
      cardIcon={buildingIcon}
      cardTitle={cart?.cartDetails?.cardTitle || "City Pass Details"}
      datetime={datetime}
      isPublished={isPublished}
      isServiceDeclined={isServiceDeclined}
      serviceDeclined={serviceDeclined}
      isMissingInfo={isMissingInfo}
    >
      <>
        <GetHelpModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} tag={cardGroup.replaceAll(' ', '')} />
        
        {isPublished &&
          <>
            <CardDetails
              cardIcon={reservationIcon}
              cardTitle="City Pass Details"
              cardSubtitle={
              <span
                style={{ whiteSpace: 'pre-line' }}
                dangerouslySetInnerHTML={{
                  __html: cartJsonData?.description,
                }}>
              </span>}
              // cardSubtitle="These City Pass tickets may be presented at each venue right from your Mobile device. Please plan ahead as some venues require reservations in advance"
            >
              {/* <div className="city-card-content">
                <div className="card-content-column">
                  <p>Description</p>
                </div>
                <div className="card-content-column">
                  <span
                    style={{ whiteSpace: 'pre-line' }}
                    dangerouslySetInnerHTML={{
                      __html: cartJsonData?.description,
                    }}
                  ></span>
                </div>
              </div> */}
            </CardDetails>

            {cartJsonData?.notes &&
              <>
                <div className="divider" />
                <CardDetails
                  cardIcon={flagIcon}
                  cardTitle="Additional Notes"
                  cardSubtitle={cartJsonData?.notes || ''}
                />
              </>
            }
          </>
        }

        {!isPublished && isMissingInfo && (
          <>
            <CardDetails
              isEditable={true}
              cardIcon={reservationIcon}
              cardTitle="City Pass Details"
              cardSubtitle="Please provide your contact info"
            >
              <>
                <div className="divider"></div>
                <Form
                  onSubmit={async (e) => {
                    e.preventDefault();

                    const emptyRequiredFieldsIds = cardState.cartData.attendeeNames.reduce(
                      (prev: any, el: { attendeeName: string }, id: number) => {
                        if (!el.attendeeName) {
                          prev.push(id);
                        }

                        return prev;
                      },
                      [],
                    );

                    if (emptyRequiredFieldsIds.length) {
                      setIsFieldsValid((prev: any) => {
                        return [...emptyRequiredFieldsIds];
                      });

                      errorMsg("Required Fields Should Not Be Empty");

                      return;
                    }

                    const { data } = await client.mutate({
                      mutation: SAVE_STAGE_ONE_CARD,
                      variables: { editCartDetailsInput: cardState },
                    });

                    const filtersData = await client.query({
                      query: GET_AVAILABLE_FILTERS_FOR_PACKAGE_INFO,
                      variables: { invoiceId: decryptedInvoiceId },
                      fetchPolicy: "no-cache",
                    });

                    if (data) {
                      setPackageOrderWithDetails((prev: any) => {
                        return {
                          ...prev,
                          orderDetails: [
                            ...prev.orderDetails.map((cart: any) => {
                              return cart.packageDetailsId === packageDetailsId
                                ? {
                                    ...cart,
                                    cartDetails: {
                                      ...cart.cartDetails,
                                      cardTitle: data?.saveStageOneCard.cardTitle,
                                      cartId: data?.saveStageOneCard.cartId,
                                      cartJsonData: data?.saveStageOneCard.cartJsonData,
                                      isPublished: data?.saveStageOneCard.isPublished,
                                      packageDetailsId: data?.saveStageOneCard.packageDetailsId,
                                    },
                                  }
                                : cart;
                            }),
                          ],
                        };
                      });

                      setMissingInfoCards(
                        filtersData.data?.getAvailableFiltersForPackageInfo.showMissingInfoFor,
                      );

                      successMsg("Saved");
                    }
                  }}
                >
                  <div className="inputs-container">
                    {cardState.cartData.attendeeNames.map((item: { attendeeName: string }, id: number) => {
                      return (
                        <div key={id} className="input-container">
                          <label className={isFieldsValid.includes(id) ? "label-invalid" : ""}>
                            Attendee Name
                          </label>
                          <div className="input-box">
                            <Input
                              className={isFieldsValid.includes(id) ? "input-invalid" : ""}
                              type="text"
                              placeholder="Attendee Name"
                              name="attendeeName"
                              invalid={isFieldsValid.includes(id)}
                              value={item.attendeeName}
                              onChange={(e) => {
                                setCardState((prev: any) => {
                                  return {
                                    ...prev,
                                    cartData: {
                                      ...prev.cartData,
                                      attendeeNames: [
                                        ...prev.cartData.attendeeNames.map((attendee: any, index: number) =>
                                          index === id
                                            ? { ...attendee, attendeeName: e.target.value }
                                            : attendee,
                                        ),
                                      ],
                                    },
                                  };
                                });
                              }}
                            />
                            <span></span>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="actions-container">
                    <CardDeclineBtn visible={isServiceDeclined} handleClick={handleDeclineClick} />
                    <Button disabled={isSubmitBtnDisabled} className="btn-round btn-icon d-flex align-items-center save-btn" type="submit">
                      Save
                    </Button>
                  </div>
                </Form>
              </>
            </CardDetails>
            <div className="divider"></div>
            <CardAction icon={helpIcon} text="Get help" 
              handleClick={() => {
                setIsModalOpen(true)
              }}
            />
          </>
        )}
        
        {!isPublished && !isMissingInfo &&
          <>
            <CardDetails
              isToggleExpanded={false}
              cardIcon={reservationIcon}
              cardTitle="City Pass Details"
              cardSubtitle="Your city pass are guaranteed."
            >
              <div className="pl-4 mb-4">
                <CardGuaranteed text="Your city pass are guaranteed." />
                <div className="actions-container">
                  <CardDeclineBtn visible={isServiceDeclined} handleClick={handleDeclineClick} />
                </div>
                <div className="divider"></div> 
                <CardAction icon={helpIcon} text="Get help" 
                  handleClick={() => {
                    setIsModalOpen(true)
                  }}
                />
              </div>
            </CardDetails>
          
            <CardDetails
              cardIcon={flagIcon}
              cardTitle="Additional Notes"
              cardSubtitle={cartJsonData?.notes || ''}
            />
          </>
        }
      </>
    </StagedCardLayout>
  );
};

export default CityPassGroupCardNew;
